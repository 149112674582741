<template>
  <div class="st-image st-form-item" :style="{
    marginBottom: myConfig.props.normal.marginBottom + 'px'
  }">
    <base-form-item
        :label="myConfig.props.normal.label"
        :required="myConfig.props.normal.required"
        :message="myConfig.field === 'res' ? '(最多9个图片/视频)' : (myConfig.field === 'check_image' ? '(需上传营业执照)' : '')"
        vertical>
      <div class="flex w-100" style="padding: 20px 0;">
        <div class="image-box flex flex-col flex-center" :class="[myConfig.field]">
          <div class="img w-100 flex-center flex-col">
            <i class="el-icon-plus"/>
            <span class="upload-msg">{{myConfig.field === 'res' ? '上传图片/视频' : ''}}</span>
          </div>
        </div>
      </div>
    </base-form-item>
  </div>
</template>

<script>
import BaseFormItem from "./BaseFormItem";

export default {
  name: "StImage",
  components: {BaseFormItem},
  props: {
    config: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        component: "",
        field: "",
        title: "",
        props: {
          normal: {
            marginBottom: 0,
            required: 0,
            label: "标题内容",
            value: "请输入",
          }
        },
      },
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
}
</script>

<style lang="scss" scoped>
  .w-100 {
    width: 100%;
  }

  .image-box {
    width: 100%;
    position: relative;
    max-width: 50%;

    &:before {
      content: "";
      display: block;
      padding-top: 57%;
      width: 100%;
    }

    &.company_history,
    &.res,
    &.logo,
    &.check_image {
      max-width: 30%;

      &:before {
        padding-top: 100%;
      }
    }

    .el-icon-plus {
      font-size: 24px;
    }

    .img {
      position: absolute;
      top: 0;
      border-radius: 8px;
      overflow: hidden;
      /*height: calc(100% - 25px);*/
      height: 100%;
      border: 0.5px dashed black;
    }
  }

  .image-box + .image-box {
    margin-left: 14px;
  }

  .upload-msg {
    display: block;
    font-size: 10px;
    margin-top: 5px;
    line-height: normal;
  }
</style>
