var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-histories",style:({
  marginTop: ((_vm.config.props.normal.marginTop || 17) + "px"),
  marginBottom: ((_vm.config.props.normal.marginBottom) + "px")
})},[_c('div',{staticClass:"single-card"},[_c('div',{staticClass:"form-item small-title"},[_c('st-input',{attrs:{"config":{props: {
      normal: {
        label: '发展历程1',
        value: ' '
      }
    }}}})],1),_c('div',{staticClass:"form-item"},[_c('st-input',{attrs:{"config":{props: {
      normal: {
        label: '时间',
        value: '请选择'
      }
    }}}})],1),_c('div',{staticClass:"form-item"},[_c('st-input',{attrs:{"config":{props: {
      normal: {
        label: '历程描述',
        value: '请填写',
        inputType: 'textarea'
      }
    }}}})],1)]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add"},[_c('i',{staticClass:"el-icon el-icon-circle-plus"}),_vm._v(" 继续添加 ")])}]

export { render, staticRenderFns }