export function getRulesByField(field) {
  return [
    {
      normal: {
        title: "常规设置",
        rules: [
          {
            "type": "StNumberSlider",
            "field": "marginBottom",
            "native": true,
            "display": true,
            "props": {
              "label": "组件间距",
              "min": 0,
              "max": 30
            }
          },
          {
            "type": "ElCheckbox",
            "field": "required",
            "title": "必填项",
            display: getIsDisplay(field, '必填项'),
            "props": {
              "true-label": 1,
              "false-label": 0
            },
            style: "float: right;",
            "children": [
              "开启"
            ]
          },
          {
            "type": "ElInput",
            "field": "label",
            "title": "标题内容",
            display: getIsDisplay(field, '标题内容'),
            "props": {
              "placeholder": "请输入标题",
              clearable: true
            }
          },
          {
            "type": "ElInput",
            "field": "value",
            "title": "提示语",
            display: getIsDisplay(field, '提示语'),
            "props": {
              "placeholder": "请输入提示语",
              clearable: true
            }
          },
          {
            "type": "StRadioButtonGroup",
            "field": "inputType",
            "title": "显示样式",
            display: getIsDisplay(field, '显示样式-单行/多行'),
            "props": {
              "options": [
                {"label": "单行显示", "value": "text"},
                {"label": "完整显示", "value": "textarea"}
              ]
            }
          },
          {
            "type": "StDragOptionList",
            "field": "options",
            "display": getIsDisplay(field, '选项内容'),
            "native": true
          },
          {
            "type": "Select",
            "field": "dateType",
            "title": "时间格式",
            "display": getIsDisplay(field, '时间格式'),
            "options": [
              {"label": "年份", "value": 0},
              {"label": "年月", "value": 1},
              {"label": "年月日", "value": 2},
              // {"label": "年月日时分", "value": 3}
            ],
            style: "width: 100%;"
          },
          {
            "type": "Select",
            "field": "showType",
            "title": "显示格式",
            "display": getIsDisplay(field, '显示格式-地区选择'),
            "options": [
              { "label": "显示省", "value": 0 },
              { "label": "显示省/市", "value": 1 },
              { "label": "显示省/区、县、镇街", "value": 2 },
              { "label": "显示省/市/区、县、镇街", "value": 3 }
            ],
            style: "width: 100%;"
          },
        ]
      },
      "pageJump": getPageJumper(field)
    }
  ]
}

// let fields = [
//   'base-input','base-select','base-time-picker','base-area-picker',
//   'name','alias_name','category_ids','type','oper_name','start_date','worker_sum','business_type',
//   'credit_code_type','credit_code','position','regist_capi','ent_cap','city','address',
//   'contact_name','contact_info','is_on_stock','is_credit_rating','is_high_new','logo',
//   'description','scope','res','check_image','party_organization','group_organization','labor_union_organization',
//   'company_product','company_history',
// ]

/* 是否显示这个字段 */
function getIsDisplay(field, title) {
  const whiteList = []
  switch (title) {
    case '必填项':
      whiteList.push('base-input')
      whiteList.push('base-select')
      whiteList.push('base-time-picker')
      whiteList.push('base-area-picker')
      whiteList.push('base-address-picker')
      whiteList.push('alias_name')
      whiteList.push('type')
      whiteList.push('oper_name')
      whiteList.push('worker_sum')
      whiteList.push('credit_code_type')
      whiteList.push('credit_code')
      whiteList.push('regist_capi')
      whiteList.push('ent_cap')
      whiteList.push('city')
      whiteList.push('contact_info')
      whiteList.push('contact_name')
      whiteList.push('is_on_stock')
      whiteList.push('is_credit_rating')
      whiteList.push('is_high_new')
      whiteList.push('logo')
      whiteList.push('res')
      whiteList.push('check_image')
      whiteList.push('party_organization')
      whiteList.push('group_organization')
      whiteList.push('labor_union_organization')
      whiteList.push('company_history')
      whiteList.push('start_date')
      whiteList.push('description')
      whiteList.push('scope')
      whiteList.push('company_product')
      whiteList.push('business_type')
      whiteList.push('position')
      whiteList.push('email')
      break;
    case '标题内容':
      whiteList.push('base-input')
      whiteList.push('base-select')
      whiteList.push('base-time-picker')
      whiteList.push('base-area-picker')
      whiteList.push('base-address-picker')
      whiteList.push('name')
      whiteList.push('alias_name')
      whiteList.push('unit_username')
      whiteList.push('phone')
      whiteList.push('address')
      whiteList.push('category_ids')
      whiteList.push('position_id')
      break
    case '显示样式-单行/多行':
      return [
        'base-input', 'base-select',
        'base-area-picker', 'base-time-picker', 'base-address-picker','description',
        'scope','name','alias_name','city','address',
        'oper_name','business_type','credit_code_type',
        'start_date','credit_code','position','email',
        'unit_username','phone','position_id','category_ids'
      ].includes(field)
    case '显示格式-地区选择':
      whiteList.push('base-area-picker')
      // whiteList.push('address')
      whiteList.push('city')
      break
    case '时间格式':
      whiteList.push('base-time-picker')
      whiteList.push('start_date')
      whiteList.push('company_history')
      break
    case '选项内容':
      whiteList.push('base-select')
      break
    case '前往设置行业类型':
      whiteList.push('category_ids')
      break
    case '提示语':
      let fields = [
          'base-input','base-select','base-time-picker','base-area-picker','base-address-picker',
        'name','alias_name','category_ids','type','oper_name','start_date','worker_sum','business_type',
        'credit_code_type','credit_code','position','regist_capi','ent_cap','city','address',
        'contact_name','contact_info','description','scope',
      ]
      return fields.includes(field);
  }
  return whiteList.includes(field)
}

function getPageJumper(field) {
  switch (field) {
    case 'category_ids':
      return {
        "title": "前往设置行业类型",
        "routeName": "CycCompanyCategory",
        "isShow": 1
      }
    default:
      return {
        isShow: 0
      }
  }
}
