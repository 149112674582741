<template>
  <div class="form-editor" v-loading="loading" :class="header.isFullscreen ? 'fullscreen':''" :style="leftBarWidth">
    <el-container>
      <!-- 顶部菜单栏 -->
      <el-header class="menu-bar" style="height: 54px;">
        <Header @header-click="handleHeaderClick"></Header>
      </el-header>
      <el-container class="workspace">
        <div class="workspace-left" style="width: auto; max-width: 50%; min-width: var(--left-barwidth);">
          <div class="resize-bar"></div>
          <div class="resize-line"></div>
          <div class="resize-save">
            <!-- 左侧菜单 -->
            <ComponentLibrary></ComponentLibrary>
          </div>
        </div>
        <el-main class="workspace-middle">
          <!-- 中间表单 -->
          <ConfigList v-if="componentLibrary.length"></ConfigList>
        </el-main>
        <el-aside class="workspace-right" style="width: 360px;">
          <!-- 右侧配置 -->
          <ConfigDetail></ConfigDetail>
        </el-aside>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {
  getFormConfig,
  saveFormConfig,
} from "../../../api/company/company-form-editor";
import Header from "../../../components/Company/FormEditor/Header";
import ComponentLibrary from "../../../components/Company/FormEditor/ComponentLibrary";
import ConfigList from "../../../components/Company/FormEditor/ConfigList";
import ConfigDetail from "../../../components/Company/FormEditor/ConfigDetail";

export default {
  components: {
    Header,
    ComponentLibrary,
    ConfigList,
    ConfigDetail,
  },
  data() {
    return {
      leftBarWidth: "--left-barwidth: " + "285px" + "; ",
      header: {
        isFullscreen: true,
      },
    };
  },
  computed: {
    ...mapState('companyFormEditor', ['loading', 'componentLibrary']),
  },
  created() {
    this.setupComponentLibrary()
    this.setupForm()
  },
  methods: {
    setupComponentLibrary() {
      let componentLibrary = require("../../../data/company-component-library.json");
      this.$store.commit("companyFormEditor/setComponentLibrary", componentLibrary)
    },
    setupForm() {
      getFormConfig()
        .then(({data}) => {
          const configs = data.configs ? data.configs : []
          const allComponents = []
          for (const group in this.componentLibrary) {
            allComponents.push(...this.componentLibrary[group].components)
          }
          configs.forEach((c) => {
            const modelProps = allComponents.find(m => m.field === c.field)?.props
            if (!modelProps) console.log(c.field, 'Error: no model! ')
            else if (modelProps.normal.options && modelProps.normal.options.length === 0) modelProps.normal.options.push('')
            c.props = this.$mergeModel(modelProps, c.props)
          })
          this.$store.commit("companyFormEditor/setConfigs", configs)
        })
        .finally(() => {
          this.$store.commit("companyFormEditor/setLoading", false)
        })
    },
    handleHeaderClick(key) {
      switch (key) {
        case 'save':
          this.$store.commit("companyFormEditor/setLoading", true);
          saveFormConfig({
            configs: this.$store.state.companyFormEditor.configs
          }).then(res => {
            this.$message.success(res.msg)
            this.$store.commit("companyFormEditor/setLoading", false);
          }).catch(() => {
            this.$store.commit("companyFormEditor/setLoading", false);
          })
          break
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .form-editor {
    overflow-x: auto;
    margin: calc(-#{$space} * 2);
    background-color: #eef2f8;

    /* 全屏 */
    &.fullscreen {
      position: fixed;
      left: calc(#{$space} * 2);
      top: calc(#{$space} * 2);
      height: 100vh;
      width: 100%;
      z-index: 11;

      .workspace {
        height: calc(100vh - 54px);
      }
    }

    .menu-bar {
      background-color: $primary;
    }

    /* 顶部菜单栏下面的一整个容器 */
    .workspace {
      height: calc(100vh - 60px - 50px - 54px);
    }

    .page-left,
    .page-right {
      background-color: white;
    }

    .workspace-left {
      overflow-x: hidden;
      overflow-y: hidden;
      position: relative;
      float: left;
    }

    .el-main.workspace-middle {
      padding: 0;
      overflow: hidden;
    }
  }

  .resize-save {
    position: absolute;
    top: 0;
    right: 5px;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
  }

  .resize-bar {
    width: var(--left-bar-width);
    min-width: var(--left-bar-width);
    max-width: 100%;
    /*height: inherit;*/
    height: calc(100vh - 54px);
    resize: horizontal;
    cursor: col-resize;
    opacity: 0;
    overflow: scroll;
  }

  /* 拖拽线 */
  .resize-line {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-right: 2px solid #eee;
    border-left: 1px solid #bbb;
    pointer-events: none;
  }

  .resize-bar:hover ~ .resize-line,
  .resize-bar:active ~ .resize-line {
    border-left: 1px dashed #3a76ff;
  }

  .resize-bar::-webkit-scrollbar {
    width: 200px;
    height: inherit;
  }

  /* Firefox只有下面一小块区域可以拉伸 */
  @supports (-moz-user-select: none) {
    .resize-bar:hover ~ .resize-line,
    .resize-bar:active ~ .resize-line {
      border-left: 1px solid #bbb;
    }
    .resize-bar:hover ~ .resize-line::after,
    .resize-bar:active ~ .resize-line::after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      bottom: 0;
      right: -8px;
      /*background: url(./resize.svg);*/
      /*background-size: 100% 100%;*/
    }
  }
</style>
