import { render, staticRenderFns } from "./PhoneHeader.vue?vue&type=template&id=00918e94&scoped=true&"
import script from "./PhoneHeader.vue?vue&type=script&lang=js&"
export * from "./PhoneHeader.vue?vue&type=script&lang=js&"
import style0 from "./PhoneHeader.vue?vue&type=style&index=0&id=00918e94&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00918e94",
  null
  
)

export default component.exports