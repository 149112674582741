<template>
  <div class="base-form-item">
    <div class="base-form-item-box">
      <div class="base-form-item-inner" :class="[vertical ? 'vertical' : 'inline']">
        <div class="base-form-item-label">
          <div v-if="required" class="form-required">*</div>
          {{handleSaaSVersionKeyword(label)}}
          <span class="msg">{{message}}</span>
        </div>
        <div class="base-form-item-content" :style="{width: vertical?'100%':'calc(100% - 70px)'}">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseFormItem",
  props: {
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Number,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    message: String
  },
};
</script>
<style lang="scss" scoped>
  .base-form-item {
    width: 100%;
    height: 100%;
    padding: 0 18px;
    background-color: white;

    .base-form-item-box {
      height: 100%;
    }

    .base-form-item-inner {
      height: 100%;
      display: flex;
      min-height: 56px;

      &.inline {
        justify-content: space-between;
        align-items: center;
      }

      &.vertical {
        flex-direction: column;

        .base-form-item-label {
          width: auto;
          white-space: normal;
          line-height: 1.5;
          padding: 16px 0 0;
        }
      }
    }

    .base-form-item-label {
      width: 70px;
      font-size: 14px;
      font-weight: bold;
      color: #191e29;
      line-height: 57px;
      position: relative;
      white-space: nowrap;

      .form-required {
        position: absolute;
        left: -10px;
        font-size: 14px;
        font-weight: bold;
        color: #dc193e;
        line-height: inherit;
      }
    }

    .base-form-item-content {
    }

    .msg {
      color: $placeholder;
    }
  }
</style>
