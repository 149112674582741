<template>
  <div class="st-form-item st-input" :style="{
        marginBottom: myConfig.props.normal.marginBottom + 'px',
    }">
    <base-form-item
        :label="myConfig.props.normal.label"
        :required="myConfig.props.normal.required"
        :vertical="vertical"
    >
      <div class="form-item-right" :class="{vertical}">
        <div class="input-placeholder">{{myConfig.props.normal.value}}</div>
      </div>
    </base-form-item>
  </div>
</template>

<script>
import BaseFormItem from "./BaseFormItem";

export default {
  name: "StInput",
  components: {
    BaseFormItem,
  },
  props: {
    config: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        component: "",
        field: "",
        title: "",
        props: {
          normal: {
            marginBottom: 0,
            required: 0,
            label: "标题内容",
            value: "请输入",
            inputType: 'text'
          }
        },
      },
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
    vertical() {
      return this.myConfig.props.normal.inputType === 'textarea'
    }
  },
}
</script>

<style lang="scss" scoped>
  .st-input {
    /*height: 56px;*/
    .form-item-right {
      padding-left: 23px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.vertical {
        padding-left: 0;
      }

      .input-placeholder {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #b7bec8;
        line-height: 56px;
      }
    }
  }
</style>
