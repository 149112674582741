import api from "@/base/utils/request";

// 获取企业表单配置
export const getFormConfig = () => {
    return api({
        url: "/admin/cyc/CompanyData/getForm",
        method: "post"
    });
}

// 保存企业表单配置
export const saveFormConfig = data => {
    return api({
        url: "/admin/cyc/CompanyData/saveForm",
        method: "post",
        data
    });
}
