<template>
  <div class="config-list">
    <div class="workspace-middle-box" @click="updateSelected('page')">
      <div class="workspace-middle-page">
        <!-- 头部背景层 -->
        <div :class="['drag-item no-hover']" style="position: absolute;">
          <phone-header />
        </div>
        <div class="workspace-middle-page-content">
          <!-- 默认头部导航 -->
          <div :class="['drag-item no-hover']">
            <st-page-set />
          </div>
          <!-- 其他组件 -->
          <draggable
              v-model="configs"
              :options="listOptions"
              :class="['draggable-box']"
              ghostClass="ghost"
              draggable=".drag-item"
              filter=".no-drag"
              animation="400"
              @sort="handleSort"
              @add="handleAdd">
            <div class="drag-item" ref="formItem"
                 v-for="(li, i) in configs.filter(el => !!el.props)" :key="li.extra.timestamp"
                 :id="'formItem' + i"
                 :class="[editing.index === i ? 'active':'',checkSameTitle(li.props.normal.label || li.title,i)?'error':'']"
                 :data-last="li.props.normal && li.props.normal.marginBottom ? li.props.normal.marginBottom > 0 : false"
                 @click.stop="updateSelected(i)">
<!--              // TODO：测试 {{li.props.normal}}-->
              <component :is="li.component" :config="li" />
              <div class="drag-item-tool">
                <div class="tools" v-if="!defaultFields.includes(li.field)">
                  <!-- 删除 -->
                  <img @click.stop="deletePageItem(i)"
                       src="../../../assets/images/company-form-editor/del_gray.png"/>
                </div>
                <div class="arrow-label" v-if="li.title"
                     :style="{display: defaultFields.includes(li.field)?'block': ''}">{{li.title}}
                </div>
              </div>
              <div class="drag-item-tool-left">
                <div class="disable-label"
                     v-if="checkSameTitle(li.props.normal.label || li.title,i)"
                     :style="{right: (168 + ((li.props.normal.label || li.props.title).length * 12)) + 'px'}">
                  不能填写相同的标题：{{li.props.normal.label || li.title}}
                </div>
              </div>
            </div>
          </draggable>
          <!-- 底部按钮 -->
          <div class="drag-item no-hover active">
            <st-bottom-button />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import draggable from "vuedraggable";
import StPageSet from "../FormComponents/StPageSet";
import StBottomButton from "../FormComponents/StBottomButton";
import StInput from "../FormComponents/StInput"
import StSelect from "../FormComponents/StSelect"
import StTimePicker from "../FormComponents/StSelect"
import StAreaPicker from "../FormComponents/StSelect"
import StMultiSelect from "../FormComponents/StSelect"
import StAddressPicker from "../FormComponents/StSelect"
import StRadio from "../FormComponents/StRadio"
import StImage from "../FormComponents/StImage"
import StMedia from "../FormComponents/StImage"
import StHistories from "@/modules/mail/components/Company/FormComponents/StHistories";
import StProducts from "@/modules/mail/components/Company/FormComponents/StProducts";
import PhoneHeader from "@/modules/mail/components/Company/FormEditor/PhoneHeader";

export default {
  name: "ConfigList",
  components: {
    PhoneHeader,
    draggable,
    StPageSet,
    StBottomButton,
    StInput,
    StSelect,
    StTimePicker,
    StAreaPicker,
    StMultiSelect,
    StAddressPicker,
    StRadio,
    StImage,
    StMedia,
    StHistories,
    StProducts
  },
  data() {
    return {
      listOptions: {
        group: {
          name: 'drag',
        },
        dragClass: 'chosen',
      },
      defaultFields: [
          'name',
          'category_ids',
          'address',
          'phone',
      ],
    };
  },
  computed: {
    ...mapState('companyFormEditor', ['editing']),
    configs: {
      get() {
        return this.$store.state.companyFormEditor.configs
      },
      set(value) {
        this.$store.commit('companyFormEditor/setConfigs', value)
      }
    }
  },
  methods: {
    /**
     * 监听"页面"拖拽排序操作
     */
    handleSort(ev) {
      if (this.isIgnore) {
        this.isIgnore = false;
      } else {
        let realIndex = ev.newIndex;
        this.updateSelected(realIndex);
      }
    },
    /**
     * 从左侧列表添加了一个组件到"页面"
     */
    handleAdd(ev) {
      // console.log('add', ev.newIndex)
      // 添加一个新的子项目时，也会触发sort事件，所以使用此字段忽略后续操作
      this.isIgnore = true;
      let realIndex = ev.newIndex;
      this.updateSelected(realIndex);
    },
    /* 表单标题查重方法 */
    checkSameTitle(title, index) {
      let flag = false;
      this.configs.forEach((el, i) => {
        if (index !== i && (el.props?.normal.label || el.props?.title) === title) {
          flag = true;
        }
      });
      return flag;
    },
    /* 选中的组件或取消选中 */
    updateSelected(key) {
      this.$store.commit('companyFormEditor/setEditing', (key === 'page') ? -1 : key)
    },
    /**
     * 删除组件
     */
    deletePageItem(i) {
      this.$store.commit("companyFormEditor/removeConfigsItem", i)
    },
  },
};
</script>

<style lang="scss" scoped>
  .config-list {
    position: relative;
    height: 100%;

    ::v-deep.el-image__error {
      display: none;
    }
  }

  .workspace-middle-box {
    padding: 63px 0 80px;
    overflow-y: auto;
    height: 100%;

    .workspace-middle-page {
      box-shadow: 0 4px 8px 0 #d5deeb;
      margin: 0 auto;
      width: 375px;
      min-height: 667px;
      position: relative;
      background-color: #f4f5f6;

      &.no-hover {
        &:before {
          display: none;
        }

        .drag-item {
          &:hover {
            outline: none;
          }

          &.active {
            outline: none;
          }
        }
      }
    }

    .bottomWatermark {
      position: absolute;
      bottom: 100px;
    }

    .workspace-middle-page-content {
      position: relative;
      min-height: 667px;

      &:before {
        content: "iPhone 8 手机高度";
        font-size: 12px;
        line-height: 17px;
        padding: 3px 12px 3px 0;
        color: #3a3a3a;
        position: absolute;
        top: 643px;
        border-bottom: 1px solid #606266;
        transform: translateX(-100%);
      }
    }
  }

  /* 固定按钮：撤销、重做 */
  .workspace-middle-undo-manage {
    position: absolute;
    right: 32px;
    bottom: 10%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    font-size: 25px;
    z-index: 3;
    height: 42px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 #d5deeb;
    border-radius: 21px;
    padding: 0 6px;

    img {
      width: 28px;
      margin: 0 6px;

      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }

      &[disabled] {
        opacity: 0.2;
        cursor: not-allowed;
      }
    }
  }

  /* 固定按钮 */
  .workspace-middle-buttons {
    position: absolute;
    top: 63px;
    right: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4;

    .el-button {
      margin-left: 0;
      margin-top: 12px;
      font-size: 12px;
      line-height: 12px;
      padding: 8px 10px;
      border: none;
      box-shadow: 0 2px 4px 0 #d5deeb;
    }

    .middle-button-content {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 4px;
        width: 16px;
      }
    }
  }

  /* 使组件放置区域变大 */
  .draggable-box {
    padding: 20px 0;
    min-height: 533px;

    &.custom {
      min-height: 617px;
    }

    &>.drag-item {
      padding: 0 17px;
    }
  }

  .drag-item-tool {
    width: 40px;
    height: 40px;
    position: absolute;
    right: -42px;
    top: 0;

    .tools {
      display: none;
      /*position: absolute;*/
      /*width: 40px;*/
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      box-shadow: 0 2px 4px 0 #d5deeb;
      border-radius: 0 2px 2px 0;
      cursor: default;

      img {
        cursor: pointer;
        font-size: 18px;
        margin: 12px 0;
        width: 20px;
      }
    }

    .arrow-label {
      display: block;
      position: absolute;
      right: 28px;
      transform: translateX(100%);
      top: 0;
      background-color: white;
      box-shadow: 0 2px 4px 0 #d5deeb;
      border-radius: 2px;
      font-size: 12px;
      color: #3a3a3a;
      line-height: 12px;
      padding: 8px 15px;
      white-space: nowrap;
      pointer-events: none;

      &::before {
        content: "\00a0";
        width: 0;
        height: 0;
        display: block;
        border-style: solid;
        border-width: 7px 16px 7px 0;
        border-color: transparent white transparent transparent;
        position: absolute;
        left: -15%;
      }
    }
  }

  .drag-item-tool-left {
    width: 40px;
    height: 40px;
    display: flex;
    position: absolute;
    left: -42px;
    top: 0;

    .disable-label {
      display: block;
      position: absolute;
      transform: translateX(100%);
      top: 10px;
      background-color: white;
      box-shadow: 0 2px 4px 0 #d5deeb;
      border-radius: 2px;
      font-size: 12px;
      color: #f56c6c;
      line-height: 12px;
      padding: 8px 15px;
      white-space: nowrap;
      pointer-events: none;

      &::after {
        content: "\00a0";
        width: 0;
        height: 0;
        display: block;
        border-style: solid;
        border-width: 7px 0 7px 16px;
        border-color: transparent white transparent;
        position: absolute;
        right: -8%;
        top: 7px;
      }
    }
  }

  .drag-item {
    width: 100%;
    position: relative;
    outline: none;

    &.fixed {
      position: absolute;
      bottom: 0;
      height: 50px;
    }

    &:hover {
      outline: 2px dashed #3479ef;
      outline-offset: -2px;
      cursor: move;

      .tools {
        display: flex;
      }

      .arrow-label {
        display: none;
      }
    }

    &.error {
      outline: 2px solid #f56c6c;
      outline-offset: -2px;
    }

    &.active {
      outline: 2px solid #3479ef;
      outline-offset: -2px;
    }

    &.no-hover {
      outline: none;
      cursor: default;
    }

    .resize {
      background-color: rgba(0, 0, 0, 0.3);
      cursor: s-resize;
    }

    & + .drag-item {
      &.hide {
        margin-top: 0;
      }
    }
  }

  /* 针对表单分组样式 */
  .drag-item {
    & + .drag-item {
      .st-form-item,
      ::v-deep.st-form-item {
        .base-form-item-inner {
          border-top: 1px solid #f1f1f1;
        }
      }
    }
  }

  .drag-item[data-last="true"] {
    .st-form-item,
    ::v-deep.st-form-item {
      /*.base-form-item-box {*/
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      overflow: hidden;
      /*}*/
    }

    & + .drag-item {
      .st-form-item,
      ::v-deep.st-form-item {
        /*.base-form-item-box {*/
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
        overflow: hidden;
        /*}*/

        .base-form-item-inner {
          border-top: none;
        }
      }
    }
  }

  .draggable-box {
    .drag-item {
      &:first-child {
        .st-form-item,
        ::v-deep.st-form-item {
          /*.base-form-item-box {*/
            border-radius: 4px 4px 0 0;
          overflow: hidden;
          /*}*/
        }
      }
    }

    /*.drag-item:nth-last-child(2), */
    .drag-item:last-child {
      .st-form-item,
      ::v-deep.st-form-item {
        /*.base-form-item-box {*/
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        overflow: hidden;
        /*}*/
      }
    }
  }
</style>
