<template>
  <div class="header-fixed">
    <!-- 返回按钮 -->
    <img :class="['default-back-icon']" src="../../../assets/images/company-form-editor/back.png"/>
    <!-- 状态栏 -->
    <img class="status-bar"
         src="../../../assets/images/company-form-editor/phone-statusbar1@2x.png"/>
  </div>
</template>

<script>
export default {
  name: "PhoneHeader",
  data() {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>
  .header-fixed {
    width: 375px;
    height: 64px;
    background-color: white;

    .default-back-icon {
      position: absolute;
      top: 33px;
      left: 11px;
      width: 20px;
      height: 20px;
    }

    .status-bar {
      position: absolute;
      top: 0;
      width: 100%;
      height: 20px;
      z-index: 2;
    }
  }
</style>
