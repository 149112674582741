<template>
  <div class="st-histories" :style="{
    marginTop: `${config.props.normal.marginTop || 17}px`,
    marginBottom: `${config.props.normal.marginBottom}px`
  }">
    <div class="single-card">
      <div class="form-item small-title">
        <st-input :config="{props: {
        normal: {
          label: '发展历程1',
          value: ' '
        }
      }}" />
      </div>
      <div class="form-item">
        <st-input :config="{props: {
        normal: {
          label: '时间',
          value: '请选择'
        }
      }}" />
      </div>
      <div class="form-item">
        <st-input :config="{props: {
        normal: {
          label: '历程描述',
          value: '请填写',
          inputType: 'textarea'
        }
      }}" />
      </div>
    </div>
    <div class="add">
      <i class="el-icon el-icon-circle-plus" />
      继续添加
    </div>
  </div>
</template>

<script>
import StInput from "@/modules/mail/components/Company/FormComponents/StInput";
import BaseFormItem from "@/modules/mail/components/Company/FormComponents/BaseFormItem";
import StImage from "@/modules/mail/components/Company/FormComponents/StImage";

export default {
  name: "StHistories",
  components: {StImage, BaseFormItem, StInput},
  /**
   * type: 0-主要产品；1-发展历程；
   */
  props: ['type', 'config'],
}
</script>

<style lang="scss" scoped>
  .st-histories {
    border-top: 2px solid transparent;
  }

  .small-title {
    ::v-deep .base-form-item .base-form-item-label {
      font-size: 12px;
    }
  }

  .single-card {
    background-color: white;
    /*margin: 10px 0;*/
    border-radius: 6px;
    overflow: hidden;

    .st-form-item:first-child {
      ::v-deep .base-form-item-inner {
        border-top: none !important;
      }
    }
  }

  .form-item {
    position: relative;
  }

  .form-item + .form-item {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 18px;
      height: 1px;
      width: 90%;
      background-color: $border-color;
    }
  }

  .right-btn {
    float: right;
    background-color: black;
    color: white;
    font-size: 10px;
    line-height: 16px;
    display: block;
    border-radius: 14px;
    text-align: center;
    padding: 0 4px;
  }

.add {
  text-align: center;
  padding: 10px;

  .el-icon {
    color: $success;
  }
}
</style>
