<template>
  <div class="config-detail">
    <div class="config-name" v-if="editing.index >= 0">{{title}}</div>
    <div class="config-name" v-else>页面设置</div>
      <div class="workspace-right-content">
<!--        {{editing.config? editing.config.props.normal :''}}-->
        <st-form-editor
            v-if="editing.index >= 0"
            v-model="editing.config.props"
            :key="editing.config.extra.timestamp"
            :component="editing.config.component"
            :field="editing.config.field">
        </st-form-editor>
      <div v-else class="st-form-editor-disable">
        <div class="disable-tip">
          <el-image class="tip-icon" :src="require('../../../assets/images/company-form-editor/disable-edit-icon.png')">
          </el-image>
          <p class="tip-text">如需设置企业库详情页，请 <router-link :to="{name: 'AppDesign'}" target="_blank">前往页面装修</router-link></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import StFormEditor from "./StFormEditor";
import {getRulesByField} from "@/modules/mail/js/form-create";

export default {
  name: "ConfigDetail",
  components: {
    StFormEditor,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('companyFormEditor', ['editing', 'componentLibrary', 'configs']),
    title() {
      const editConfig = this.editing.config
      const editField = editConfig?.field
      for (let i = 0; i < this.componentLibrary.length; i++) {
        const cur = this.componentLibrary[i]
        for (let j = 0; j < cur.components.length; j++) {
          const current = cur.components[j]
          if (current.field === editField) return `${current.title}设置`
        }
      }
      return `${editConfig.props.normal.label || editConfig.title}设置`
    }
  },
  methods: {
    getRules(field) {
      return getRulesByField(field)
    }
  },
};
</script>

<style lang="scss" scoped>
  .config-detail {
    position: relative;
    height: calc(100vh - 54px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 120px;
    background: #FDFDFD;
  }

  .config-name {
    font-weight: bold;
    line-height: 80px;
    background-color: #f7f7f7;
    position: sticky;
    top: 0;
    z-index: 5;
    height: 80px;
    color: #3a3a3a;
    font-size: 18px;
    padding: 0 24px;
  }

  .workspace-right-content {
    padding: 20px 19px 0 24px;

    .component-list-editor {
    }

    .component-list-controls {
      text-align: right;
    }

    /* 占位 */
    .ghost-component-item {
      height: 1px !important;
      /*width: 60%;*/
      overflow: hidden;
      color: $primary !important;
      position: relative;
      box-shadow: 0 2px 4px 0 #d5deeb;
      opacity: 0.8;
      background-color: white;
      padding-top: 10px;

      &::before {
        content: "";
        /*width: 8px;*/
        /*height: 8px;*/
        /*border-radius: 50%;*/
        height: 1px;
        width: 100%;
        border-top: 1px solid $primary;
        /*position: absolute;*/
      }
    }

    /* 拖拽时 */
    .chosen-component-item {
      background-color: white;
      /*box-shadow: 0 2px 4px 0 #D5DEEB;*/
      /*opacity: 0.8;*/
      cursor: move;
    }

    .component-list {
      .component-item {
        width: 100%;
        height: 44px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 8px;
        color: #3a3a3a;

        &:hover {
          background-color: #e9f1fd;
          color: $primary;

          .component-item-name {
          }
        }

        .component-item-name {
          font-size: 14px;
          line-height: 14px;
        }

        .component-item-del {
          width: 20px;
          min-width: 20px;
        }
      }
    }
  }

  // 组件配置不可编辑缺省页样式
  .st-form-editor-disable {
    width: 100%;
    height: calc(100vh - 284px);
    display: flex;
    justify-content: center;
    align-items: center;

    .disable-tip {
      text-align: center;
      .tip-icon {
        width: 210px;
        height: 100px;
      }
      .tip-text {
        margin-top: 20px;
        font-size: 14px;
        color: #999999;
        line-height: 14px;
      }
    }
  }
</style>
