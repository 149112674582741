<template>
    <div class="st-form-item st-radio" :style="{marginBottom: myConfig.props.normal.marginBottom + 'px'}">
        <base-form-item
                :label="myConfig.props.normal.label"
                :required="myConfig.props.normal.required"
        >
            <div class="form-item-right">
                <el-radio
                        v-model="myConfig.user_data"
                        :label="index"
                        v-for="(option,index) in myConfig.props.normal.options"
                        :key="option">
                    {{option}}
                </el-radio>
            </div>
        </base-form-item>
    </div>
</template>

<script>
import BaseFormItem from "./BaseFormItem";

export default {
    name: "StRadio",
    components: {
        BaseFormItem,
    },
    props: {
        config: {
            type: Object,
            default: function() {
                return {};
            },
        },
    },
    data() {
        return {
            // 定义 config 格式和默认数据
            model: {
                component: "",
                field: "",
                title: "",
                user_data: 0,
                props: {
                    normal: {
                        marginBottom: 0,
                        required: 0,
                        label: "标题内容",
                        value: 0,
                        options: [""],
                    }
                },
            },
        }
    },
    computed: {
        myConfig() {
            return this.$mergeModel(this.model, this.config);
        },
    },
}
</script>

<style lang="scss" scoped>
.st-radio {
    /*height: 56px;*/
    .form-item-right {
        padding-left: 23px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
</style>
