<template>
  <div class="component-library">
    <div class="title">自定义表单组件</div>
    <el-collapse class="groups-layout" v-model="activeComponentGroups">
      <el-collapse-item :name="group.group" v-for="(group,i) in componentLibrary" :key="i">
        <template slot="title">
          <i class="el-icon-caret-right el-tree-node__expand-icon"
             style="margin-left: 8px;margin-right: 8px;">
          </i>
          <span>{{group.name}}</span>
        </template>
        <draggable
            class="components-layout"
            v-model="group.components"
            ghostClass="ghost"
            :options="draggableOptions"
            filter=".undraggable"
            :clone="(original) => handleClone(original)">
          <div class="component-item"
               v-for="(component,j) in group.components" :key="j"
               :class="(component.extra.count >= component.extra.maxCount) ? 'undraggable' : 'draggable'"
          >
            <div class="box">
              <!-- 未激活图标 -->
              <img class="icon"
                   :src="require(`../../../assets/images/company-form-editor/${component.icon || 'st-default'}.png`)"/>
              <!-- 激活图标 -->
              <img class="icon active"
                   :src="require(`../../../assets/images/company-form-editor/${component.icon || 'st-default'}__active.png`)"/>
              <div class="text">{{component.title}}</div>
              <div class="count" v-if="component.extra">
                {{component.extra.count}}/{{component.extra.maxCount}}
              </div>
            </div>
            <div class="placement">
              <span>组件放置区域</span>
            </div>
          </div>
        </draggable>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import {mapState} from "vuex";
import draggable from "vuedraggable";

export default {
  name: "ConfigLibrary",
  components: {
    draggable,
  },
  data() {
    return {
      activeComponentGroups: [],
      draggableOptions: {
        group: {
          name: "drag",
          pull: "clone",
          put: false,
        },
        sort: false,
        dragClass: "chosen",
      },
    };
  },
  computed: {
    ...mapState('companyFormEditor', ['componentLibrary']),
  },
  mounted() {
    // setup activeComponentGroups
    this.activeComponentGroups = this.componentLibrary.map((it) => {
      return it.group
    })
  },
  methods: {
    handleClone(original) {
      let copy = JSON.parse(JSON.stringify(original));
      // 不加唯一标识会造成数据混乱，保存页面配置时可忽略
      copy.extra.timestamp = new Date().getTime()
      return copy;
    },
  },
};
</script>

<style lang="scss" scoped>
  .component-library {
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
      font-weight: bold;
      line-height: 80px;
      background-color: #f7f7f7;
      position: sticky;
      top: 0;
      z-index: 5;
      height: 80px;
      color: #3a3a3a;
      font-size: 18px;
      padding: 0 24px;
      white-space: nowrap;
    }

    .groups-layout {
      padding-bottom: 50px;
      background-color: #fff;
      flex: 1;
    }
  }

  .el-collapse {
    .el-collapse-item__header {
      border-bottom: none;
      height: 36px;
      font-size: 14px;

      &.is-active {
        .el-tree-node__expand-icon {
          transform: rotate(90deg);
        }
      }
    }

    .el-collapse-item__wrap {
      border-bottom: none;
    }

    .el-collapse-item__content {
      padding-bottom: 0;
    }

    .el-collapse-item__arrow {
      display: none;
    }
  }

  .components-layout {
    display: grid;
    grid-template-columns: repeat(auto-fill, 66.5px);
    justify-content: space-around;
    padding: 0 14px;

    .component-item {
      padding: 4px 0;
      cursor: move;
      text-align: center;

      &:not(.undraggable) {
        &:hover,
        &.active {
          color: white;

          .box {
            background-color: $primary;
          }

          .icon {
            display: none;

            &.active {
              display: block;
            }
          }

          .text,
          .count {
            color: white;
          }
        }
      }

      .box {
        border-radius: 2px;
        width: 100%;
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .icon {
        width: 32px;
        height: 32px;

        &.active {
          display: none;
        }
      }

      .text,
      .count {
        font-size: 12px;
        line-height: 12px;
      }

      .text {
        color: #3a3a3a;
        margin-top: 8px;
      }

      .count {
        color: #999999;
        margin-top: 8px;
      }
    }

    .placement {
      display: none !important;
    }
  }

  /* 选中样式 */
  .chosen {
    /*opacity: 0;*/

    .placement {
      display: none;
    }
  }

  /* 占位样式 */
  .ghost {
    .box {
      display: none;
    }

    .placement {
      height: 49px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        padding: 0 9px;
        color: white;
      }
    }

    .placement {
      background: linear-gradient(
              to right,
              rgba(53, 118, 255, 0.4) 12.5%,
              rgba(53, 118, 255, 0.2) 0
      );
      background-size: 8px 100%;

      span {
        background: #6f9aff;
      }
    }
  }

  .undraggable {
    opacity: .3;
    cursor: not-allowed !important;
  }
</style>
