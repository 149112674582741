var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-histories",style:({
  marginTop: ((_vm.config.props.normal.marginTop || 17) + "px"),
  marginBottom: ((_vm.config.props.normal.marginBottom) + "px")
})},[_c('div',{staticClass:"single-card"},[_c('div',{staticClass:"form-item small-title"},[_c('st-input',{attrs:{"config":{props: {
      normal: {
        label: '主要产品1',
        value: ' '
      }
    }}}})],1),_c('div',{staticClass:"form-item"},[_c('st-input',{attrs:{"config":{props: {
      normal: {
        label: '产品名称',
        value: '请填写'
      }
    }}}})],1),_c('div',{staticClass:"form-item"},[_c('base-form-item',{attrs:{"label":"产品标签"}},[_c('span',{staticClass:"right-btn"},[_vm._v("点击输入")])])],1),_c('div',{staticClass:"form-item"},[_c('st-input',{attrs:{"config":{props: {
      normal: {
        label: '产品介绍',
        value: '请填写',
        inputType: 'textarea'
      }
    }}}})],1),_c('div',{staticClass:"form-item"},[_c('st-image',{attrs:{"config":{
        field: 'company_history',
        props: {
          normal: {
          label: '产品图片'
        }
        }
      }}})],1)]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add"},[_c('i',{staticClass:"el-icon el-icon-circle-plus"}),_vm._v(" 继续添加 ")])}]

export { render, staticRenderFns }