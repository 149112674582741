<template>
  <div>
    <div class="flex-col">
      <div class="editor-item"
           v-for="key in Object.keys(myValue || configs[editing.index].props || editing.config.props)" :key="key">
        <template v-if="rulesArr && rulesArr[key]">
          <!-- 在form-create.json中，与rules同级的isShow属性控制配置界面是否可见 -->
          <div v-show="rulesArr[key].isShow === undefined || rulesArr[key].isShow === 1">
            <p class="form-title" v-if="rulesArr[key].title"><span>{{rulesArr[key].title}}</span>
            </p>
            <!--            {{rulesArr[key].rules}}-->
            <form-create :rule="rulesArr[key].rules" v-model="fApi" :option="options"
                         :value.sync="myValue[key]"/>
          </div>
        </template>
      </div>
      <div class="jump-btn" v-if="rulesArr && rulesArr.pageJump && rulesArr.pageJump.isShow">
        <el-button size="mini" plain @click="handleJumpPage">{{rulesArr.pageJump.title}}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

/* form-create 组件挂载 */
import StRadioButtonGroup from "@/modules/mail/components/Company/FormComponents/StRadioButtonGroup";
import StNumberSlider from "../FormComponents/StNumberSlider";
import StDragOptionList from "@/modules/mail/components/Company/FormComponents/StDragOptionList";
import formCreate from "@form-create/element-ui";

formCreate.component(StNumberSlider)
formCreate.component(StRadioButtonGroup)
formCreate.component(StDragOptionList)

/* form-create 表单规则 */
import {getRulesByField} from "@/modules/mail/js/form-create";

export default {
  name: "StFormEditor",
  components: {
    formCreate: formCreate.$form(),
  },
  model: {
    prop: "value",
    event: "updateValue",
  },
  props: {
    value: {
      type: [Object, Array],
      required: true,
    },
    // example: "st-page-set"
    component: {
      type: String,
    },
    field: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fApi: {},
      options: {
        // 整体表单样式规则
        form: {
          size: "small",
          labelWidth: "96px",
          labelPosition: "left",
        },
        submitBtn: {
          show: false,
        },
        mounted: (fApi) => {
          fApi.rule.forEach((item, i) => {

          });
        },
        onSubmit: (formData) => {
          console.log(formData);
        },
      },
    }
  },
  computed: {
    ...mapState('companyFormEditor', ['editing', 'configs']),
    rulesArr() {
      return this.getRules(this.field)
    },
    myValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("updateValue", val);
      },
    },
  },
  methods: {
    getRules(field) {
      return getRulesByField(field)[0]
    },
    // 表单底部页面跳转
    handleJumpPage() {
      let routerData =
        this.$router.resolve({
          name: this.rulesArr.pageJump.routeName,
        })
      window.open(routerData.href, "_blank");
    },
  },
}
</script>

<style lang="scss" scoped>
  // 表单标题样式
  .form-title {
    font-weight: bold;
    font-size: 16px;
    color: #3a3a3a;
    line-height: 16px;
    padding: 20px 0 24px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
  }

  .jump-btn {
    /*position: sticky;*/
    bottom: 0;
    z-index: 2;
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    .el-button {
      width: 100%;
    }
  }
</style>
