<template>
  <div class="st-bottom-button">
    <div class="button-content">
      保存
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
.st-bottom-button {
  padding: 13px 18px;
  width: 100%;
  height: 70px;
  /*background: #ffffff;*/
  /*<!--box-shadow: 0px -2px 4px 0px rgba(238, 238, 238, 0.5);-->*/

  .button-content {
    text-align: center;
    width: 100%;
    height: 100%;
    background: #3a7aff;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    line-height: 44px;
    letter-spacing: 8px;
  }
}
</style>
